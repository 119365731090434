import { createTheme } from "@mui/material/styles";
import { ThemeType } from "../types/type";

// 'The General' palette of colors
const primaryColor = "#FFFFFF"; // White
const secondaryColor = "#007A53"; // Green
const headerTopBorderColor = "#ccc"; // Gray
const headerBottomBorderColor = "#FFF";
const headerBackgroundColor = "linear-gradient(#efefef, #ffffff)"; // Linear Gradient
const mainBackgroundColor = "#E5E5E5"; // Very light gray
const listSelectionColor = "#F5F5F5"; // Not so light gray
const footerColor = "#444444"; // Black
const footerTextColor = "#FFFFFF";
const footerTopBorderColor = "#6E6E6E"; // Very light gray
const searchButtonBorderRadius = "32px";
const listItemHoverColor = "#F5F5F5";
// Links for header and Header
const opcoName = "The General";
const logoLink = "https://www.thegeneral.com/";
const contactUsText = "Contact Us";
const contactUsLink = "https://www.thegeneral.com/about/contact/";
const companyInformationText = "Company Information";
const companyInformationLink = "https://www.thegeneral.com/";
const termsAndConditionsText = "Terms & Conditions";
const termsAndConditionsLink =
  "https://www.thegeneral.com/legal/terms-and-conditions/";
const privacyPolicyText = "Privacy Policy";
const privacyPolicyLink = "https://www.thegeneral.com/legal/privacy-policy/";
const legalDisclosuresText = "Legal Disclosures";
const legalDisclosuresLink =
  "https://www.thegeneral.com/legal/legal-disclosures/";
const dontSellPersonalInfoText = "Don’t Sell My Personal Information";
const dontSellPersonalInfoLink =
  "https://www.thegeneral.com/legal/personal-information/";

// Contact number
const contactNumber = "800-280-1466";
// Texts
const footerText = `The General Automobile Insurance Services Inc. (The General), an insurance agency, writes auto insurance through affiliated companies and offers other lines of business through affiliated and unaffiliated partners. See legal disclosures for more information.
Product offerings, coverages, payment plans, discounts, and features are subject to verification and vary by state and program. Individual experience may vary. Rates and potential savings are subject to qualifications and vary by state and program.
*Average annual savings data based on information provided by customers surveyed countrywide in September 2020 and February 2021 who switched to The General and experienced savings.
Shaq™; Rights of Publicity and Persona Rights: ABG-Shaq, LLC. shaq.com

©2010 - ${new Date().getFullYear()} Permanent General Companies, Inc.`;
const title = "Find a Repair Shop";
const description =
  "Our 5-Star Repair Program takes the stress out of choosing a repair shop and offers a lifetime workmanship guarantee. \nSearch by city, shop name or ZIP code and connect with a trusted, customer-friendly repair shop near you.";
const noShopsFoundMessage =
  "\nTry searching for another location.\n\nFor immediate repair shop assistance contact us at " +
  `${contactNumber}`;

export const theGeneralTheme = createTheme({
  palette: {
    primary: {
      main: `${secondaryColor}`,
    },
  },
  custom: {
    colors: {
      primaryColor: `${primaryColor}`,
      secondaryColor: `${secondaryColor}`,
      headerTopBorderColor: `${headerTopBorderColor}`,
      headerBottomBorderColor: `${headerBottomBorderColor}`,
      headerBackgroundColor: `${headerBackgroundColor}`,
      mainBackgroundColor: `${mainBackgroundColor}`,
      listSelectionBackgroundColor: `${listSelectionColor}`,
      footerBackGroundColor: `${footerColor}`,
      footerTextColor: `${footerTextColor}`,
      footerTopBorderColor: `${footerTopBorderColor}`,
      listItemHoverColor: `${listItemHoverColor}`,
    },
    links: {
      contactUsLink: `${contactUsLink}`,
      logoLink: `${logoLink}`,
      termsAndConditionsLink: `${termsAndConditionsLink}`,
      companyInformationLink: `${companyInformationLink}`,
      privacyPolicyLink: `${privacyPolicyLink}`,
      legalDisclosuresLink: `${legalDisclosuresLink}`,
      dontSellPersonalInfoLink: `${dontSellPersonalInfoLink}`,
    },
    text: {
      footerText: `${footerText}`,
      title: `${title}`,
      description: `${description}`,
      contactNumber: `${contactNumber}`,
      noShopsFoundMessage: `${noShopsFoundMessage}`,
      opcoName: `${opcoName}`,
      contactUsText: `${contactUsText}`,
      companyInformationText: `${companyInformationText}`,
      termsAndConditionsText: `${termsAndConditionsText}`,
      privacyPolicyText: `${privacyPolicyText}`,
      legalDisclosuresText: `${legalDisclosuresText}`,
      dontSellPersonalInfoText: `${dontSellPersonalInfoText}`,
    },
    styles: {
      headerContactUs: {
        fontFamily: "Open Sans",
        fontWeight: 700,
        fontSize: "16px",
        color: `${secondaryColor}`,
      },
      headerTitle: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "48px",
        color: "#393939",
      },
      primaryDescription: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "16px",
        color: "#393939",
      },
      secondaryDescription: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "18px",
        color: "#232323",
      },
      themeType: {
        currentThemeType: ThemeType.THE_GENERAL,
      },
      searchButtonBorderRadius: `${searchButtonBorderRadius}`,
    },
  },
});
