import { createTheme } from "@mui/material/styles";
import { ThemeType } from "../types/type";

// 'MSA' palette of colors
const primaryColor = "#0B3B60"; // Dark Blue
const secondaryColor = "#0B3B60"; // Light Blue
const headerTopBorderColor = "#002855"; // Dark blue
const headerBottomBorderColor = "#FFF";
const mainBackgroundColor = "#E5E5E5"; // Very light gray
const listSelectionColor = "#F5F5F5"; // Not so light gray
const footerColor = "#000"; // Black
const footerTextColor = "#FFF";
const footerTopBorderColor = "#c9c9c9"; // Very light gray
const searchButtonBorderRadius = "4px";
const listItemHoverColor = "#FAFAFA";
// Links for header
const contactUsText = "Contact Us";
const contactUsLink = "https://msainsurance.com/contact";
const logoLink = "https://msainsurance.com/";
const opcoName = "Main Street America Insurance";
// Links for Footer
const companyInformationText = "Company Information";
const companyInformationLink =
  "https://msainsurance.com/newsroom/our-companies";
const termsAndConditionsText = "Terms & Conditions";
const termsAndConditionsLink =
  "https://msainsurance.com/sites/default/files/Terms_and_Conditions.pdf";
// Contact number
const contactNumber = "877-425-2467";
// Texts
const footerText = `©2010 - ${new Date().getFullYear()} Main Street America Insurance. All rights reserved.`;
const title = "Find a Repair Shop";
const description =
  "Our Value in Partnership Program takes the stress out of choosing a repair shop and offers a lifetime workmanship guarantee. \nSearch by city, shop name or ZIP code and connect with a trusted, customer-friendly repair shop near you.";
const noShopsFoundMessage =
  "\nTry searching for another location.\n\nFor immediate repair shop assistance contact us at " +
  `${contactNumber}`;

export const msaTheme = createTheme({
  palette: {
    primary: {
      main: `${secondaryColor}`,
    },
  },
  custom: {
    colors: {
      primaryColor: `${primaryColor}`,
      secondaryColor: `${secondaryColor}`,
      headerTopBorderColor: `${headerTopBorderColor}`,
      headerBottomBorderColor: `${headerBottomBorderColor}`,
      mainBackgroundColor: `${mainBackgroundColor}`,
      listSelectionBackgroundColor: `${listSelectionColor}`,
      footerBackGroundColor: `${footerColor}`,
      footerTextColor: `${footerTextColor}`,
      footerTopBorderColor: `${footerTopBorderColor}`,
      listItemHoverColor: `${listItemHoverColor}`,
    },
    links: {
      contactUsLink: `${contactUsLink}`,
      logoLink: `${logoLink}`,
      termsAndConditionsLink: `${termsAndConditionsLink}`,
      companyInformationLink: `${companyInformationLink}`,
    },
    text: {
      footerText: `${footerText}`,
      title: `${title}`,
      description: `${description}`,
      contactNumber: `${contactNumber}`,
      noShopsFoundMessage: `${noShopsFoundMessage}`,
      opcoName: `${opcoName}`,
      contactUsText: `${contactUsText}`,
      companyInformationText: `${companyInformationText}`,
      termsAndConditionsText: `${termsAndConditionsText}`,
    },
    styles: {
      headerContactUs: {
        fontFamily: "Open Sans",
        fontWeight: 700,
        fontSize: "16px",
        color: "white",
      },
      headerTitle: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "36px",
        color: "#000",
      },
      primaryDescription: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "16px",
        color: "#25282A",
      },
      secondaryDescription: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "18px",
        color: "#232323",
      },
      themeType: {
        currentThemeType: ThemeType.MSA,
      },
      searchButtonBorderRadius: `${searchButtonBorderRadius}`,
    },
  },
});
