import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { ShopsInfo } from "../../model/shops.response";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { getShortHoursOfOperation } from "../../Utils/Utils";

interface Shop {
  shop: ShopsInfo;
}

const ShopHoursAndDistance = (prop: Shop) => {
  const theme: Theme = useTheme();
  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={4}
        title="ShopHoursAndDistance"
        tabIndex={0}
      >
        <Grid item md={6}>
          <Box
            sx={{
              width: "100%",
              borderRight: "1px solid #707070",
              marginBottom: "1rem",
            }}
          >
            {/* <Stack direction="column"> */}
            <Typography
              variant="body2"
              sx={{
                color: {
                  ...theme.custom.styles.primaryDescription,
                  fontSize: "14px",
                  padding: "0px 10px 0px 15px",
                },
              }}
            >
              {"Opening Hours"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: {
                  ...theme.custom.styles.primaryDescription,
                  fontSize: "14px",
                  fontWeight: 700,
                  paddingLeft: "10px",
                  whiteSpace: "pre-line",
                  padding: "0px 10px 0px 15px",
                },
              }}
            >
              {prop.shop.hoursOfOperation &&
                getShortHoursOfOperation(prop.shop.hoursOfOperation)}
            </Typography>
            {/* </Stack> */}
          </Box>
        </Grid>

        <Grid item md={6}>
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="body2"
              sx={{
                color: {
                  ...theme.custom.styles.primaryDescription,
                  fontSize: "14px",
                  padding: "0px 10px 0px 10px",
                },
              }}
            >
              {"Distance Away"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: {
                  ...theme.custom.styles.primaryDescription,
                  fontSize: "14px",
                  fontWeight: 700,
                  paddingLeft: "10px",
                  whiteSpace: "pre-wrap",
                  padding: "0px 10px 10px 10px",
                },
              }}
            >
              {`${prop.shop.distanceInfo.distance} miles`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ShopHoursAndDistance;
