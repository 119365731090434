import { ShopsInfo } from "../model/shops.response";

export const getAddressLine = (shop: ShopsInfo) => {
  let address = "";
  address = `${shop.address.addressLine1}${shop.address.addressLine2}, ${shop.address.city}, ${shop.address.state} ${shop.address.zipCode}`;
  return address;
};

export const getShortHoursOfOperation = (hours: string) => {
  const shortString = hours
    .replace("Sunday", "Sun")
    .replace("Monday", "Mon")
    .replace("Tuesday", "Tue")
    .replace("Wednesday", "Wed")
    .replace("Thursday", "Thu")
    .replace("Friday", "Fri")
    .replace("Saturday", "Sat")
    .replace(/ AM/g, " am")
    .replace(/ PM/g, " pm");
  return shortString;
};
