import { createTheme } from "@mui/material/styles";
import { ThemeType } from "../types/type";

// 'CAH' palette of colors
const primaryColor = "#FFFFFF"; // White
const secondaryColor = "#287BA4"; // Light Blue
const headerTopBorderColor = "#002855"; // Dark blue
const headerBottomBorderColor = "#FFF";
const mainBackgroundColor = "#E5E5E5"; // Very light gray
const listSelectionColor = "#F5F5F5"; // Not so light gray
const footerColor = "#FAFAFA"; // Black
const footerTextColor = `${secondaryColor}`;
const footerTopBorderColor = "#c9c9c9"; // Very light gray
const searchButtonBorderRadius = "4px";
const listItemHoverColor = "#FAFAFA";

// Links for header
const contactUsText = "Contact Us";
const contactUsLink = "https://www.connectbyamfam.com/help/contact-us/";
const logoLink = "https://www.connectbyamfam.com/";
const opcoName = "Connect By American Family Insurance";
// Links for Footer
const companyInformationText = "Company Information";
const companyInformationLink =
  "https://www.connectbyamfam.com/legal/connect-company-information/";
const termsAndConditionsText = "Terms & Conditions";
const termsAndConditionsLink =
  "https://www.connectbyamfam.com/legal/connect-terms-of-use/";
// Contact number
const contactNumber = "800-872-5246";
// Texts
const footerText = `©2010 - ${new Date().getFullYear()} American Family Connect Property and Casualty Insurance Company, Inc. All rights reserved.`;
const title = "Find a Repair Shop";
const description =
  "Our Value in Partnership Program takes the stress out of choosing a repair shop and offers a lifetime workmanship guarantee. \nSearch by city, shop name or ZIP code and connect with a trusted, customer-friendly repair shop near you.";
const noShopsFoundMessage =
  "\nTry searching for another location.\n\nFor immediate repair shop assistance contact claims at " +
  `${contactNumber}`;

export const cahTheme = createTheme({
  palette: {
    primary: {
      main: `${secondaryColor}`,
    },
  },

  custom: {
    colors: {
      primaryColor: `${primaryColor}`,
      secondaryColor: `${secondaryColor}`,
      headerTopBorderColor: `${headerTopBorderColor}`,
      headerBottomBorderColor: `${headerBottomBorderColor}`,
      mainBackgroundColor: `${mainBackgroundColor}`,
      listSelectionBackgroundColor: `${listSelectionColor}`,
      footerBackGroundColor: `${footerColor}`,
      footerTextColor: `${footerTextColor}`,
      footerTopBorderColor: `${footerTopBorderColor}`,
      listItemHoverColor: `${listItemHoverColor}`,
    },
    links: {
      contactUsLink: `${contactUsLink}`,
      logoLink: `${logoLink}`,
      termsAndConditionsLink: `${termsAndConditionsLink}`,
      companyInformationLink: `${companyInformationLink}`,
    },
    text: {
      footerText: `${footerText}`,
      title: `${title}`,
      description: `${description}`,
      contactNumber: `${contactNumber}`,
      noShopsFoundMessage: `${noShopsFoundMessage}`,
      opcoName: `${opcoName}`,
      contactUsText: `${contactUsText}`,
      companyInformationText: `${companyInformationText}`,
      termsAndConditionsText: `${termsAndConditionsText}`,
    },
    styles: {
      headerContactUs: {
        fontFamily: "Open Sans",
        fontWeight: 700,
        fontSize: "16px",
        color: `${secondaryColor}`,
      },
      headerTitle: {
        fontFamily: "Oswald",
        fontWeight: 600,
        fontSize: "28px",
        color: `${headerTopBorderColor}`,
      },
      primaryDescription: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "16px",
        color: "#25282A",
      },
      secondaryDescription: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "18px",
        color: "#232323",
      },
      themeType: {
        currentThemeType: ThemeType.CAH,
      },
      searchButtonBorderRadius: `${searchButtonBorderRadius}`,
    },
  },
});
