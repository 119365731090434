import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Theme,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { ShopsInfo } from "../../model/shops.response";
import { PaginationData } from "../../types/type";
import Pages from "../Pagination/Pages";
import Address from "../ShopAddress/ShopAddress";
import { ChevronRightIcon } from "../svg-Icons/AppIcons";
import ShopHoursAndDistance from "../ShopHoursDistance/ShopHoursAndDistance";
import { DEFAULT_LIST_SIZE_PER_PAGE } from "../../AppConstants";
interface Props {
  shops: ShopsInfo[] | undefined;
  shopCardSelectionHandler: (shop: ShopsInfo) => void;
  paginationData: PaginationData | null | undefined;
  pageSelectionChangeHandler: (pageNumber: number) => void;
}

const ShopInfoCard = (props: Props) => {
  const theme: Theme = useTheme();
  const handleShopCardOnClick = (shop: ShopsInfo) => {
    props.shopCardSelectionHandler(shop);
  };

  const shopSelectionStateCheck = () => {
    if (props.shops) {
      const status = props.shops.some((item) => item.isActive);
      return status;
    }
  };

  const cardStyling = {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.custom.colors.listItemHoverColor,
    },
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          maxHeight: "54vh",
          overflowY: "scroll",
          overflowX: "hidden",
          width: "100%",
          paddingRight: "2rem",
          [theme.breakpoints.down("md")]: {
            marginBottom: "2rem",
            paddingBottom: "8rem",
          },
          [theme.breakpoints.up("md")]: {
            marginBottom: "1rem",
            paddingBottom: "1 rem",
          },
        }}
        data-testid="shopsList"
      >
        {props.shops &&
          props.shops?.map((shop, index) => (
            <React.Fragment key={shop.id}>
              <Box
                sx={{
                  boxShadow: "0px 4px 12px 1px rgb(128, 128, 128, 0.5)",
                  m: "1rem",
                  width: "100%",
                  display: "grid",
                }}
              >
                <Card
                  variant="outlined"
                  sx={cardStyling}
                  id={`${"automation_card_" + shop.id}`}
                >
                  <Stack direction="column">
                    <CardActionArea
                      data-testid={`shopsListItem-${index}`}
                      onClick={() => {
                        handleShopCardOnClick(shop);
                      }}
                      disableRipple={shop.isActive ? true : false}
                    >
                      <Grid container sx={{ padding: "15px" }}>
                        <Grid container item xs={10}>
                          <CardContent
                            sx={{
                              p: "0px",
                              "&:last-child": {
                                paddingBottom: 0,
                              },
                            }}
                          >
                            <Address shop={shop} />
                          </CardContent>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={2}
                          alignItems="center"
                          justifyContent="space-around"
                        >
                          {!shop.isActive && (
                            <ChevronRightIcon
                              sx={{ color: theme.custom.colors.secondaryColor }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </CardActionArea>
                    <Grid item>
                      {shop.isActive && <ShopHoursAndDistance shop={shop} />}
                    </Grid>
                  </Stack>
                </Card>
              </Box>
            </React.Fragment>
          ))}
        <Box sx={{ margin: "1rem 0rem 1rem 1rem", width: "100%" }}>
          {!shopSelectionStateCheck() &&
            props.paginationData &&
            props.paginationData?.totalResultCount >
              DEFAULT_LIST_SIZE_PER_PAGE && (
              <Pages
                paginationData={props.paginationData}
                pageSelectionChangeHandler={props.pageSelectionChangeHandler}
              />
            )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ShopInfoCard;
