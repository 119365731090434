import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { ShopNotFoundTypography } from "./ShopNotFound-Styles";

const ShopsNotFound = () => {
  const theme: Theme = useTheme();

  return (
    <React.Fragment>
      <Box
        title="NoShopsFoundMessage"
        tabIndex={0}
        sx={{
          m: "2rem 2rem 2rem 1rem",
          lineHeight: "2rem",
          whiteSpace: "pre-line",
        }}
      >
        <ShopNotFoundTypography variant="body2">
          {"We can’t find any shops matching your search terms."}
        </ShopNotFoundTypography>
        <Typography variant="body2" sx={theme.custom.styles.primaryDescription}>
          {`${theme.custom.text.noShopsFoundMessage}`}
        </Typography>
      </Box>
    </React.Fragment>
  );
};
export default ShopsNotFound;
