import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GenericDescriptionTypography } from "./Description-Styles";

const Description = () => {
  const theme: Theme = useTheme();

  return (
    <GenericDescriptionTypography
      variant="body2"
      gutterBottom
      tabIndex={0}
      data-testid="automation_drp-shop-description"
      sx={
        theme.custom.text.opcoName === "The General"
          ? { marginTop: "11rem" }
          : { marginTop: "10rem" }
      }
    >
      {theme.custom.text.description}
    </GenericDescriptionTypography>
  );
};

export default Description;
