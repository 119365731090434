import { FullAddress } from "../../types/type";

export async function getShops(
  fullAddress: FullAddress,
  distance: string,
  opcoId: string
) {
  try {
    let URI = "";
    if (fullAddress.houseNumber && fullAddress.streetName) {
      URI = `${process.env.REACT_APP_BASE_URL}claimvendorlocations/v1/shops?state=${fullAddress.stateName}&shopName=${fullAddress.houseNumber}%20${fullAddress.streetName}&city=${fullAddress.cityName}&distance=${distance}&id=${opcoId}`;
    } else if (fullAddress.zipCode) {
      URI = `${process.env.REACT_APP_BASE_URL}claimvendorlocations/v1/shops?zip=${fullAddress.zipCode}&distance=${distance}&id=${opcoId}`;
    } else if (fullAddress.latitude && fullAddress.longitude) {
      URI = `${process.env.REACT_APP_BASE_URL}claimvendorlocations/v1/shops?longitude=${fullAddress.longitude}&latitude=${fullAddress.latitude}&distance=${distance}&id=${opcoId}`;
    }
    let headers = new Headers();
    headers.append("AFI-API-Key", `${process.env.REACT_APP_API_KEY}`);
    headers.append("AFI-AppName", `${process.env.REACT_APP_AFI_APP_NAME}`);

    const response = await fetch(URI, {
      method: "GET",
      headers: headers,
    });
    if (response.status >= 200 && response.status <= 299) {
      const responseData = await response.json();
      if (responseData.status.code === 200) {
        return responseData;
      } else {
        throw new Error("'getShops' failed");
      }
    } else {
      throw new Error("'getShops' failed");
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}
