import { styled } from "@mui/system";
import { Button, useTheme } from "@mui/material";

export const MainSearchButton = styled(Button)(() => ({
  fontFamily: "open sans",
  border: "none",
  color: "white",
  margin: "1rem",
  cursor: "pointer",
  width: "76px",
  height: "56px",
  borderRadius: `${useTheme().custom.styles.searchButtonBorderRadius}`,
  marginTop: "1.6rem",
  backgroundColor: `${useTheme().custom.colors.secondaryColor}`,
  [useTheme().breakpoints.down("xl")]: {
    width: "100%",
    marginLeft: "0rem",
    marginRight: "1rem",
    fontSize: "16px",
    fontWeight: 700,
  },
  [useTheme().breakpoints.down("md")]: {
    width: "100%",
    marginLeft: "0rem",
    marginRight: "0rem",
    fontSize: "16px",
    fontWeight: 700,
  },
  [useTheme().breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: "0rem",
    marginRight: "0rem",
    fontSize: "16px",
    fontWeight: 700,
  },
  [useTheme().breakpoints.up("xl")]: {
    fontSize: "0px",
    paddingRight: "1.5rem",
  },
}));
