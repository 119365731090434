import { createTheme } from "@mui/material/styles";
import { ThemeType } from "../types/type";

// 'The General' palette of colors
const primaryColor = "#FFFFFF"; // White
const secondaryColor = "#003DA5"; // Blue
const headerTopBorderColor = "#D50032"; // Red
const headerBottomBorderColor = "#BFCADE";
const headerBackgroundColor = "#F0F2F5";
const mainBackgroundColor = "#E5E5E5"; // Very light gray
const listSelectionColor = "#F5F5F5"; // Not so light gray
const footerColor = "#F0F2F5"; // Black
const footerTextColor = "#003DA5";
const footerTopBorderColor = "#FFF"; // Very light gray
const searchButtonBorderRadius = "4px";
const listItemHoverColor = "#E8F0FF";

// Links for header
const contactUsText = "Contact Us";
const contactUsLink = "https://www.amfam.com/contact";
const logoLink = "https://www.amfam.com/";
const opcoName = "American Family Insurance";
// Links for Footer
const companyInformationText = "Company Information";
const companyInformationLink = "https://www.amfam.com/about";
const termsAndConditionsText = "Terms & Conditions";
const termsAndConditionsLink = "https://www.amfam.com/terms-and-conditions";
// Contact number
const contactNumber = "800-692-6326";
// Texts
const footerText = `©2010 - ${new Date().getFullYear()} American Family Mutual Insurance Company, S.I. All rights reserved.`;
const title = "FIND A REPAIR SHOP";
const description =
  "Our Dreams Restored Program takes the stress out of choosing a repair shop and offers a lifetime workmanship guarantee. \nSearch by city, shop name or ZIP code and connect with a trusted, customer-friendly repair shop near you.";
const noShopsFoundMessage =
  "\nTry searching for another location.\n\nFor immediate repair shop assistance contact us at " +
  `${contactNumber}`;

export const amfamTheme = createTheme({
  palette: {
    primary: {
      main: `${secondaryColor}`,
    },
  },
  custom: {
    colors: {
      primaryColor: `${primaryColor}`,
      secondaryColor: `${secondaryColor}`,
      headerTopBorderColor: `${headerTopBorderColor}`,
      headerBottomBorderColor: `${headerBottomBorderColor}`,
      headerBackgroundColor: `${headerBackgroundColor}`,
      mainBackgroundColor: `${mainBackgroundColor}`,
      listSelectionBackgroundColor: `${listSelectionColor}`,
      footerBackGroundColor: `${footerColor}`,
      footerTextColor: `${footerTextColor}`,
      footerTopBorderColor: `${footerTopBorderColor}`,
      listItemHoverColor: `${listItemHoverColor}`,
    },
    links: {
      contactUsLink: `${contactUsLink}`,
      logoLink: `${logoLink}`,
      termsAndConditionsLink: `${termsAndConditionsLink}`,
      companyInformationLink: `${companyInformationLink}`,
    },
    text: {
      footerText: `${footerText}`,
      title: `${title}`,
      description: `${description}`,
      contactNumber: `${contactNumber}`,
      noShopsFoundMessage: `${noShopsFoundMessage}`,
      opcoName: `${opcoName}`,
      contactUsText: `${contactUsText}`,
      companyInformationText: `${companyInformationText}`,
      termsAndConditionsText: `${termsAndConditionsText}`,
    },
    styles: {
      headerContactUs: {
        fontFamily: "Open Sans",
        fontWeight: 700,
        fontSize: "16px",
        color: `${secondaryColor}`,
      },
      headerTitle: {
        fontFamily: "Oswald",
        fontWeight: 600,
        fontSize: "20px",
        color: "#384252",
      },
      primaryDescription: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "16px",
        color: "#384252",
      },
      secondaryDescription: {
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "18px",
        color: "#232323",
      },
      themeType: {
        currentThemeType: ThemeType.AMFAM,
      },
      searchButtonBorderRadius: `${searchButtonBorderRadius}`,
    },
  },
});
