import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { DistanceUpdate } from "../../types/type";
import { DEFAULT_DISTANCE } from "../../AppConstants";

const DistanceSelector = (props: DistanceUpdate) => {
  const theme: Theme = useTheme();
  const [distance, setDistance] = React.useState(DEFAULT_DISTANCE);

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    setDistance(event.target.value);
    props.distanceUpdateHandler(event.target.value as string);
  };

  return (
    <FormControl fullWidth>
      <label
        htmlFor="Distance"
        style={theme.custom.styles.secondaryDescription}
      >
        Distance
      </label>
      <Select
        labelId="simple-select-label"
        id="automation_select_distance"
        data-testid="automation_select_distance"
        value={distance}
        onChange={handleChange}
      >
        <MenuItem value={5} id="automation_menuItem_5">
          <Typography sx={theme.custom.styles.secondaryDescription}>
            5 Miles
          </Typography>
        </MenuItem>
        <MenuItem value={10} id="automation_menuItem_10">
          <Typography sx={theme.custom.styles.secondaryDescription}>
            10 Miles
          </Typography>
        </MenuItem>
        <MenuItem value={15} id="automation_menuItem_15">
          <Typography sx={theme.custom.styles.secondaryDescription}>
            15 Miles
          </Typography>
        </MenuItem>
        <MenuItem value={25} id="automation_menuItem_25">
          <Typography
            sx={theme.custom.styles.secondaryDescription}
            data-testid="25_Miles"
          >
            25 Miles
          </Typography>
        </MenuItem>
        <MenuItem value={30} id="automation_menuItem_30" data-testid="30_Miles">
          <Typography sx={theme.custom.styles.secondaryDescription}>
            30 Miles
          </Typography>
        </MenuItem>
        <MenuItem value={45} id="automation_menuItem_45">
          <Typography sx={theme.custom.styles.secondaryDescription}>
            45 Miles
          </Typography>
        </MenuItem>
        <MenuItem value={60} id="automation_menuItem_60">
          <Typography sx={theme.custom.styles.secondaryDescription}>
            60 Miles
          </Typography>
        </MenuItem>
        <MenuItem value={75} id="automation_menuItem_75">
          <Typography sx={theme.custom.styles.secondaryDescription}>
            75 Miles
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
export default DistanceSelector;
