import { styled } from "@mui/system";
import { Link, useTheme, Grid } from "@mui/material";

export const FooterMainGrid = styled(Grid)(() => ({
  width: "100%",
  bottom: 0,
  left: 0,
  minHeight: 41,
  paddingLeft: "3rem",
  paddingRight: "3rem",
  paddingBottom: "1rem",
  backgroundColor: `${useTheme().custom.colors.footerBackGroundColor}`,
  borderTop: `2px solid ${useTheme().custom.colors.footerTopBorderColor}`,
}));

export const FooterLink = styled(Link)(() => ({
  ...useTheme().custom.styles.primaryDescription,
  color: `${useTheme().custom.colors.footerTextColor}`,
  fontWeight: 600,
  textDecorationColor: `${useTheme().custom.colors.footerTextColor}`,
  fontSize: "12px",
}));

export const GridItemFooterLink = styled(Grid)(() => ({
  ...useTheme().custom.styles.primaryDescription,
  [useTheme().breakpoints.down("md")]: {
    textAlign: "center",
  },
}));
export const FooterCopyRightGrid = styled(Grid)(() => ({
  fontWeight: 400,
  fontFamily: `${useTheme().custom.styles.primaryDescription}`,
  fontSize: "12px",
}));
