import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
import { AlertIcon } from "../svg-Icons/AppIcons";
import { PageNotFoundTypography } from "./PageNotFound-Styles";

export default function NotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: "2rem",
          width: 1,
          height: 128,
          p: "2rem",
        },
      }}
      title="automation_not_found_title"
    >
      <Paper elevation={0}>
        <Stack
          direction="row"
          spacing={4}
          justifyContent="flex-start"
          alignItems="center"
        >
          <AlertIcon
            viewBox="0 0 20 20"
            sx={{
              overflow: "visible",
              color: "#8A0200",
              paddingBottom: "1rem",
            }}
          />
          <PageNotFoundTypography gutterBottom variant="h5" tabIndex={0}>
            This page is currently unavailable.
          </PageNotFoundTypography>
        </Stack>
      </Paper>
    </Box>
  );
}
