import { Stack } from "@mui/material";
import { SearchButtonClick } from "../../types/type";
import { SearchIcon } from "../svg-Icons/AppIcons";
import { MainSearchButton } from "./SearchButton-Styles";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";

const SearchButton = (props: SearchButtonClick) => {
  const theme: Theme = useTheme();

  return (
    <Stack direction="row" spacing={2}>
      <MainSearchButton
        onClick={props.searchButtonClickHandler}
        id="automation_button_search"
        data-testid="automation_button_search"
        variant="outlined"
        sx={{
          "&.MuiButtonBase-root:hover": {
            bgcolor: theme.custom.colors.secondaryColor,
          },
          textTransform: "none",
        }}
        endIcon={<SearchIcon />}
      >
        Search
      </MainSearchButton>
    </Stack>
  );
};

export default SearchButton;
