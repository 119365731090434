/* istanbul ignore file */
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SearchIcon() {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32">
      <title>Search Button</title>
      <path d="M13.714 4.983c4.427 0.058 7.994 3.657 8 8.091v1.349c0 0.745-0.604 1.349-1.349 1.349s-1.349-0.604-1.349-1.349v0-1.349c-0.006-2.945-2.366-5.337-5.297-5.394h-0.005c-0.745 0-1.349-0.604-1.349-1.349s0.604-1.349 1.349-1.349v0zM13.714 0c0.001 0 0.001 0 0.002 0 7.574 0 13.714 6.14 13.714 13.714 0 3.312-1.174 6.35-3.129 8.721l0.019-0.023 7.28 7.303c0.25 0.24 0.405 0.576 0.405 0.949s-0.155 0.709-0.404 0.948v0c-0.242 0.245-0.577 0.397-0.948 0.4h-0.001c-0.369-0.009-0.699-0.165-0.937-0.411v0l-7.291-7.28c-2.346 1.935-5.384 3.109-8.695 3.109-7.574 0-13.714-6.14-13.714-13.714 0-7.569 6.132-13.706 13.7-13.714h0.001zM13.714 24c5.681 0 10.286-4.605 10.286-10.286s-4.605-10.286-10.286-10.286c-5.681 0-10.286 4.605-10.286 10.286v0c0 5.681 4.605 10.286 10.286 10.286v0z"></path>
    </SvgIcon>
  );
}

export function LocationIcon() {
  return (
    <SvgIcon width="26" height="20" viewBox="0 0 26 20">
      <title>Location Icon</title>
      <path d="M17.081 0.231c-0.356-0.038-0.719-0.056-1.081-0.056s-0.725 0.019-1.081 0.056c-6.819 0.694-10.569 8.375-7.494 14.5l8.575 17.094 8.575-17.094c3.075-6.125-0.675-13.806-7.494-14.5zM16 13.6c-1.575 0-2.85-1.275-2.85-2.85s1.275-2.85 2.85-2.85 2.85 1.275 2.85 2.85-1.275 2.85-2.85 2.85z"></path>
    </SvgIcon>
  );
}

export function PreferredShopIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <title>Preferred Shop Icon</title>
      <path d="M15.969 0.919l3.756 11.275 11.887 0.094-9.563 7.056 3.587 11.337-9.669-6.913-9.669 6.913 3.581-11.337-9.563-7.056 11.887-0.094z"></path>
    </SvgIcon>
  );
}

export function WebRedirectIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <title>Webpage Redirect Icon</title>
      <path d="M21.656 17.2v10.25h-17.075v-17.075h10.119l3.65-3.65h-17.419v24.381h24.375v-17.563zM17.706 0.894v3.481h7.425l-7.55 7.544 2.462 2.463 7.55-7.55v7.431h3.475v-13.369z"></path>
    </SvgIcon>
  );
}

export function ChevronRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <title>Left Chevron Button</title>
      <path d="M9.9 31.5l-3.3-3.3 12.2-12.2-12.2-12.1 3.3-3.4 15.5 15.5-15.5 15.5z"></path>
    </SvgIcon>
  );
}

export function AlertIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <title>Alert Icon</title>
      <path d="M16 0.5c-8.563 0-15.5 6.938-15.5 15.5s6.938 15.5 15.5 15.5 15.5-6.938 15.5-15.5-6.938-15.5-15.5-15.5zM17.538 26.2h-3.35v-3.813h3.35v3.813zM17.813 12.744l-1.331 5.638h-1.206l-1.338-5.644v-7.188h3.875v7.194z"></path>
    </SvgIcon>
  );
}

export function ChevronLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <title>Back Button</title>
      <path d="M22.1 0.5l3.3 3.3-12.2 12.2 12.1 12.1-3.3 3.4-15.4-15.5 15.5-15.5z"></path>
    </SvgIcon>
  );
}
