import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DistanceSelector from "../DistanceSelection/DistanceSelector";
import SearchButton from "../SearchButton/SearchButton";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import {
  FullAddress,
  PaginationData,
  PlaceType,
  ThemeType,
} from "../../types/type";
import ShopInfoCard from "../ShopInfo/ShopInfoCard";
import { DRPShopResponse, ShopsInfo } from "../../model/shops.response";
import { getShops } from "../../services/shopDetails/getShopsApi";
// Imports for suggestion and map
import throttle from "lodash/throttle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { getAddressDetailsByPlaceId } from "../../services/placeDetails/placeDetailsApi";
import {
  DEFAULT_DISTANCE,
  DEFAULT_LIST_SIZE_PER_PAGE,
  DEFAULT_ZOOM,
} from "../../AppConstants";
import Address from "../ShopAddress/ShopAddress";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import BackNavigation from "../BackNavigation/BackNavigation";
import ShopsNotFound from "../ShopsNotFound/ShopsNotFound";

interface OpcoIdInfo {
  opcoId: string;
}
// Constants for suggestion and map
const autocompleteService = { current: null };
const libraries: Libraries = ["places"];
const Search = (props: OpcoIdInfo) => {
  const theme: Theme = useTheme();
  // There is certain style differences for 'The General'
  const isCurrentThemeTheGeneral =
    theme.custom.styles.themeType?.currentThemeType === ThemeType.THE_GENERAL;
  // over all State management
  const [distance, setDistance] = useState<string>(DEFAULT_DISTANCE);
  const [address, setAddress] = useState<FullAddress>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>();
  const [shopSearchResults, setShopSearchResults] = useState<
    ShopsInfo[] | null
  >();

  // State management for Autocomplete
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  // State management for Markers
  const [selectedMarker, setSelectedMarker] = React.useState<ShopsInfo | null>(
    null
  );

  // State management for Card display, card selection and Pagination
  const [shopResultsToShow, setShopResultsToShow] = useState<
    ShopsInfo[] | null
  >();
  const [paginationData, setPaginationData] = useState<PaginationData>({
    pageCount: 0,
    starting: 0,
    ending: 0,
    totalResultCount: 0,
  });
  // This value keeps track of card selection state
  const [isCardSelected, setIsCardSelected] = useState(false);

  // User Interaction Handling
  const distanceUpdateHandler = async (distance: string) => {
    setDistance(distance);
  };

  const searchButtonClickHandler = async () => {
    if (address) {
      setLoading(true);
      setShopResultsToShow(null);
      setError(null);
      const searchResults: DRPShopResponse = await getShops(
        address,
        distance,
        props.opcoId
      );
      if (searchResults) {
        setLoading(false);
        const preferredShops: ShopsInfo[] = [];
        const otherShops: ShopsInfo[] = [];
        searchResults.shops.forEach((shop) => {
          shop.tier === "Tier1"
            ? preferredShops.push(shop)
            : otherShops.push(shop);
        });
        preferredShops.sort(
          (shop1, shop2) =>
            Number(shop1.distanceInfo.distance ?? 0) -
            Number(shop2.distanceInfo.distance ?? 0)
        );
        otherShops.sort(
          (shop1, shop2) =>
            Number(shop1.distanceInfo.distance ?? 0) -
            Number(shop2.distanceInfo.distance ?? 0)
        );
        const fullShopList = [...preferredShops, ...otherShops];
        // Initially there are no shops which are selected. Adding that to the object collection.
        const updatedFullList = fullShopList.map((shop) => {
          return { ...shop, isActive: false };
        });
        setShopSearchResults(updatedFullList);
      } else {
        setError(new Error("'getShops' failed"));
      }
    }
  };

  const shopCardSelectionHandler = (shop: ShopsInfo) => {
    shop.isActive = true;
    setIsCardSelected(true);
    setSelectedMarker(shop);
  };

  const resetSelectedMarker = () => {
    setIsCardSelected(false);
    setSelectedMarker(null);
    // When a card is no longer selected reset all states, including the 'isActive' property of the object collection
    const updatedFullList =
      shopSearchResults &&
      shopSearchResults.map((shop) => {
        return { ...shop, isActive: false };
      });
    setShopSearchResults(updatedFullList);
  };
  const backNavigationClickHandler = () => {
    resetSelectedMarker();
  };

  const pageSelectionChangeHandler = (pageNumber: number) => {
    getShopsToShow(pageNumber);
  };
  // Loading Indicator messages
  let content = <></>;
  if (!isLoading && shopResultsToShow?.length && !error) {
    content = (
      <ShopInfoCard
        shops={
          isCardSelected
            ? shopResultsToShow.filter((shop) => shop.isActive) // If a shop is selected, show only that
            : shopResultsToShow
        }
        shopCardSelectionHandler={shopCardSelectionHandler}
        paginationData={paginationData}
        pageSelectionChangeHandler={pageSelectionChangeHandler}
      ></ShopInfoCard>
    );
  }
  if (isLoading) {
    content = <LoadingIndicator message={"Looking for nearby shops"} />;
  }
  if (error) {
    content = <ShopsNotFound />;
  }

  const memoedFetch = React.useMemo(
    () =>
      throttle(
        (
          request: {
            input: string;
            componentRestrictions: { country: string };
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  const getShopsToShow = useCallback(
    (page: number) => {
      if (shopSearchResults) {
        const startIndex = (page - 1) * DEFAULT_LIST_SIZE_PER_PAGE;
        const endIndex = startIndex + DEFAULT_LIST_SIZE_PER_PAGE;
        // Below logic handle the case for last page data
        const realEndIndex =
          endIndex < shopSearchResults.length
            ? endIndex
            : shopSearchResults.length;
        const shopsToShow = shopSearchResults.slice(startIndex, realEndIndex);

        setShopResultsToShow(shopsToShow);
        setPaginationData((prevState) => {
          return {
            ...prevState,
            starting: startIndex + 1, // Adding '1' since array inde start at '0'
            ending: realEndIndex,
          };
        });
      }
    },
    [shopSearchResults]
  );

  // UseEffect handler for Pagination
  useEffect(() => {
    const getPaginationData = () => {
      let pageCount = 0;
      if (shopSearchResults) {
        // Set the page count
        pageCount = Math.ceil(
          shopSearchResults.length / DEFAULT_LIST_SIZE_PER_PAGE
        );
        const initialPaginationData: PaginationData = {
          pageCount,
          starting: 0,
          ending: 0,
          totalResultCount: shopSearchResults.length,
        };
        setPaginationData(initialPaginationData);
        // Set the page content array
        getShopsToShow(1); // Passing 1 by default
      }
    };
    getPaginationData();
  }, [getShopsToShow, shopSearchResults]);

  // UseEffect handler for search suggestion
  useEffect(() => {
    const placeId = value?.place_id;
    const getFullAddressByPlaceId = async () => {
      if (placeId) {
        const fullAddress = await getAddressDetailsByPlaceId(placeId);
        fullAddress && setAddress(fullAddress);
      }
    };
    getFullAddressByPlaceId();
  }, [value]);

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    memoedFetch(
      { input: inputValue, componentRestrictions: { country: "us" } },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];
          if (value) {
            newOptions = [value];
          }
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, memoedFetch]);

  const { isLoaded: isMapLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
    libraries,
  });

  const mapContainerStyle = {
    height: "95%",
    width: "100%",
    border: shopSearchResults ? "1px solid #ccc" : "0px",
  };

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  let mapOptions = undefined;
  if (isMapLoaded) {
    mapOptions = {
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      disableDefaultUI: true,
      zoomControl: true,
      minZoom: 5,
      maxZoom: 20,
    };
  }

  const autoComplete = (
    <React.Fragment>
      <Autocomplete
        id="automation_input_search"
        data-testid="automation_input_search"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        forcePopupIcon={true}
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Search"
            inputProps={{
              ...params.inputProps,
              style: {
                ...theme.custom.styles.secondaryDescription,
              },
            }}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: "text.secondary", mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={theme.custom.styles.secondaryDescription}
                  >
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </React.Fragment>
  );

  const searchControls = (
    <React.Fragment>
      <Grid item xs={12} xl={6} md={7}>
        <label
          htmlFor="search"
          style={theme.custom.styles.secondaryDescription}
        >
          Search
        </label>
        <div>{autoComplete}</div>
      </Grid>
      <Grid item xs={12} xl={3} md={5}>
        <Box
          sx={{
            minWidth: 110,
            paddingRight: {
              sm: "0rem",
              xs: "0rem",
              lg: "1rem",
              md: "1rem",
              xl: "1rem",
            },
            paddingLeft: {
              sm: "0rem",
              xs: "0rem",
              lg: "1rem",
              md: "1rem",
              xl: "1rem",
            },
          }}
        >
          <DistanceSelector distanceUpdateHandler={distanceUpdateHandler} />
        </Box>
      </Grid>
      <Grid item xs={12} xl={3}>
        <SearchButton searchButtonClickHandler={searchButtonClickHandler} />
      </Grid>
    </React.Fragment>
  );

  const mapControls = (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          [theme.breakpoints.down("md")]: {
            height: "30%",
            width: "100%",
            minHeight: shopSearchResults ? "30vh" : "0vh",
          },
          [theme.breakpoints.up("md")]: {
            marginLeft: "1rem",
            height: shopSearchResults ? "100%" : "0%",
            width: "100%",
            minHeight: shopSearchResults ? "60vh" : "0vh",
          },
        }}
      >
        {isMapLoaded && (
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={DEFAULT_ZOOM}
            center={{
              lat: shopResultsToShow
                ? +shopResultsToShow[0].address.coordinates.latitude
                : 0,
              lng: shopResultsToShow
                ? +shopResultsToShow[0].address.coordinates.longitude
                : 0,
            }}
            onLoad={onMapLoad}
            options={mapOptions}
          >
            {shopResultsToShow &&
              !isLoading &&
              shopResultsToShow.map((shop) => (
                <Marker
                  key={shop.id}
                  position={{
                    lat: +shop.address.coordinates.latitude,
                    lng: +shop.address.coordinates.longitude,
                  }}
                  onClick={() => {
                    setSelectedMarker(shop);
                  }}
                ></Marker>
              ))}
            {selectedMarker ? (
              <InfoWindow
                position={{
                  lat: +selectedMarker.address.coordinates.latitude,
                  lng: +selectedMarker.address.coordinates.longitude,
                }}
                onCloseClick={() => {
                  setSelectedMarker(null);
                }}
                options={{
                  pixelOffset: new google.maps.Size(0, -45),
                  disableAutoPan: false,
                }}
              >
                <Address shop={selectedMarker} />
              </InfoWindow>
            ) : null}
          </GoogleMap>
        )}
      </Box>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          padding: "1rem 0px",
          [theme.breakpoints.up("sm")]: {
            marginBottom: isCurrentThemeTheGeneral ? "15rem" : "7rem",
          },
          [theme.breakpoints.up("lg")]: {
            marginBottom: isCurrentThemeTheGeneral ? "10rem" : "5rem",
          },
        }}
      >
        <Grid
          item
          container
          sm={12}
          md={6}
          lg={4}
          order={{ md: 1, lg: 1, xl: 1, xs: 2, sm: 2 }}
        >
          <Box
            sx={{
              flexGrow: 1,
              borderRight: {
                sm: "0px",
                xs: "0px",
                md: "2px solid #ccc",
                lg: "2px solid #ccc",
                xl: "2px solid #ccc",
              },
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                [theme.breakpoints.down("md")]: {
                  marginTop: "1rem",
                },
              }}
            >
              {/* If no shops are selected from the list, show the search controls */}
              {!isCardSelected && searchControls}
              {/* If a shop is selected from the list, show the back navigation control */}
              {isCardSelected && (
                <BackNavigation
                  backNavigationClickHandler={backNavigationClickHandler}
                />
              )}
              {content}
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          item
          sm={12}
          md={6}
          lg={8}
          order={{ md: 2, lg: 2, xl: 2, xs: 1, sm: 1 }}
        >
          <Box
            sx={{
              flexGrow: 1,
              [theme.breakpoints.down("md")]: {
                marginLeft: "0rem",
              },
              [theme.breakpoints.up("md")]: {
                marginLeft: "1rem",
              },
            }}
          >
            {mapControls}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Search;
