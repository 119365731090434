import { Box, Grid } from "@mui/material";
import { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";

import {
  FooterCopyRightGrid,
  FooterLink,
  FooterMainGrid,
  GridItemFooterLink,
} from "./Footer-Styles";
import { ThemeType } from "../../types/type";

const Footer = () => {
  const theme: Theme = useTheme();
  // There are certain style differences for 'The General' and 'CAH'
  const isCurrentThemeTheGeneral =
    theme.custom.styles.themeType?.currentThemeType === ThemeType.THE_GENERAL;
  const isCurrentThemeCAH =
    theme.custom.styles.themeType?.currentThemeType === ThemeType.CAH;
  return (
    <Fragment>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <FooterMainGrid
          direction={isCurrentThemeTheGeneral ? "column" : "row"}
          container
          spacing={{
            xs: 1.5,
            sm: 1.5,
            md: 0,
            lg: 0,
            xl: 0,
          }}
          sx={{
            [theme.breakpoints.up("sm")]: {
              position: "fixed",
            },
            [theme.breakpoints.down("sm")]: {
              position: "relative",
            },
          }}
        >
          <Grid
            container
            item
            md={4}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <GridItemFooterLink item xs={12} sm={12} md="auto">
              {isCurrentThemeTheGeneral && (
                <FooterLink
                  href={theme.custom.links.termsAndConditionsLink}
                  target="_blank"
                  underline="always"
                >
                  {theme.custom.text.termsAndConditionsText}
                </FooterLink>
              )}
              {!isCurrentThemeCAH && !isCurrentThemeTheGeneral && (
                <FooterLink
                  href={theme.custom.links.contactUsLink}
                  target="_blank"
                  underline="always"
                >
                  {theme.custom.text.contactUsText}
                </FooterLink>
              )}
            </GridItemFooterLink>
            <GridItemFooterLink item xs={12} sm={12} md="auto">
              <FooterLink
                href={
                  isCurrentThemeTheGeneral
                    ? theme.custom.links.privacyPolicyLink
                    : theme.custom.links.companyInformationLink
                }
                target="_blank"
              >
                {isCurrentThemeTheGeneral
                  ? theme.custom.text.privacyPolicyText
                  : theme.custom.text.companyInformationText}
              </FooterLink>
            </GridItemFooterLink>
            <GridItemFooterLink item xs={12} sm={12} md="auto">
              <FooterLink
                href={
                  isCurrentThemeTheGeneral
                    ? theme.custom.links.legalDisclosuresLink
                    : theme.custom.links.termsAndConditionsLink
                }
                target="_blank"
              >
                {isCurrentThemeTheGeneral
                  ? theme.custom.text.legalDisclosuresText
                  : theme.custom.text.termsAndConditionsText}
              </FooterLink>
            </GridItemFooterLink>
            {isCurrentThemeTheGeneral && (
              <GridItemFooterLink item xs={12} sm={12} md="auto">
                <FooterLink
                  href={theme.custom.links.dontSellPersonalInfoLink}
                  target="_blank"
                >
                  {theme.custom.text.dontSellPersonalInfoText}
                </FooterLink>
              </GridItemFooterLink>
            )}
          </Grid>
          <FooterCopyRightGrid
            container
            item
            md={isCurrentThemeTheGeneral ? 12 : 8}
            justifyContent={{
              md: "center",
              sm: "center",
              xs: "center",
            }}
            alignItems={isCurrentThemeTheGeneral ? "center" : "flex-end"}
            sx={
              isCurrentThemeTheGeneral
                ? { color: "#DEDEDE" }
                : { color: theme.custom.colors.footerTextColor }
            }
            tabIndex={0}
          >
            <Grid item>{theme.custom.text.footerText}</Grid>
          </FooterCopyRightGrid>
        </FooterMainGrid>
      </Box>
    </Fragment>
  );
};

export default Footer;
