/* istanbul ignore file */
import { styled } from "@mui/system";
import { Box, useTheme } from "@mui/material";

export const AppMainBox = styled(Box)(() => ({
  [useTheme().breakpoints.down("md")]: {
    margin: "1rem",
  },
  [useTheme().breakpoints.up("md")]: {
    margin: "1rem 5rem",
  },
}));
