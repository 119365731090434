import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Grid, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Logo from "../Logo/Logo";
import {
  HeaderBorderDiv,
  HeaderLink,
  HeaderMainDiv,
  SecondaryHeaderTypography,
} from "./Header-Styles";
import { ThemeType } from "../../types/type";

const Header = () => {
  const theme: Theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={0} sx={{ backgroundColor: "white" }}>
        <Toolbar
          disableGutters
          sx={{
            backgroundColor: `${theme.custom.colors.primaryColor}`,
          }}
        >
          <Grid
            container
            justifyContent="space-evenly"
            sx={{
              [theme.breakpoints.down("md")]: {
                marginLeft: "1rem",
                marginRight: "1rem",
              },
              [theme.breakpoints.up("md")]: {
                marginLeft: "5rem",
                marginRight: "5rem",
              },
            }}
          >
            <Grid
              item
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
              sm={6}
              data-testid="automation_opco_logo"
            >
              <Logo
                themeType={theme.custom.styles.themeType?.currentThemeType}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* Dont show Contact Us link for 'CAH' */}
              {theme.custom.styles.themeType?.currentThemeType !==
                ThemeType.CAH && (
                <HeaderLink
                  href={theme.custom.links.contactUsLink}
                  target="_blank"
                  data-testid="automation_contact_us_link"
                >
                  {"Contact Us"}
                </HeaderLink>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        <Toolbar
          disableGutters
          style={{
            minHeight: "62px",
            background: `${theme.custom.colors.headerBackgroundColor}`,
            borderBottom: `1px solid ${
              useTheme().custom.colors.headerBottomBorderColor
            }`,
          }}
        >
          <HeaderBorderDiv>
            <HeaderMainDiv
              sx={
                theme.custom.text.opcoName === "The General"
                  ? { marginTop: "20px" }
                  : { marginTop: "0px" }
              }
            >
              <div tabIndex={0}>
                <SecondaryHeaderTypography
                  gutterBottom
                  sx={{ pl: "5rem", m: "0 auto" }}
                  title="automation_drp_shop_title"
                >
                  {theme.custom.text.title}
                </SecondaryHeaderTypography>
              </div>
            </HeaderMainDiv>
          </HeaderBorderDiv>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
