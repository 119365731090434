import React from "react";
import { Link, Stack } from "@mui/material";
import { ShopsInfo } from "../../model/shops.response";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { getAddressLine } from "../../Utils/Utils";
import { PreferredShopIcon, WebRedirectIcon } from "../svg-Icons/AppIcons";
import {
  PreferredShopTypography,
  ShopNameTypography,
  ShopAddressTypography,
  ShopPhoneNumberAndWebSiteTypography,
} from "./ShopAddress-Styles";

interface Shop {
  shop: ShopsInfo;
}

const Address = (prop: Shop) => {
  const theme: Theme = useTheme();

  return (
    <React.Fragment>
      {prop.shop.tier === "Tier1" && (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
          data-testid="shop_address"
        >
          <PreferredShopIcon fontSize="small" sx={{ color: "#ffc320" }} />
          <PreferredShopTypography
            variant="caption"
            display="block"
            gutterBottom
          >
            Preferred Shop
          </PreferredShopTypography>
        </Stack>
      )}
      <ShopNameTypography
        variant="body2"
        sx={{ fontFamily: `${theme.custom.styles.primaryDescription}` }}
      >
        {prop.shop.name}
      </ShopNameTypography>
      <ShopAddressTypography variant="body2">
        {getAddressLine(prop.shop)}
      </ShopAddressTypography>
      <ShopPhoneNumberAndWebSiteTypography variant="body2">
        {prop.shop.contactMethodDetail.primaryPhoneNumber}
      </ShopPhoneNumberAndWebSiteTypography>

      {prop.shop.contactMethodDetail.websiteAddresses[0] && (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <ShopPhoneNumberAndWebSiteTypography variant="body2">
            <Link
              href={prop.shop.contactMethodDetail.websiteAddresses[0]}
              target="_blank"
              sx={{ textDecoration: "none" }}
            >
              {" Visit Website"}
            </Link>
          </ShopPhoneNumberAndWebSiteTypography>
          <WebRedirectIcon
            fontSize="small"
            sx={{ color: `${theme.custom.colors.secondaryColor}` }}
          />
        </Stack>
      )}
    </React.Fragment>
  );
};

export default Address;
