import { Grid, Pagination } from "@mui/material";
import React from "react";
import { PaginationData } from "../../types/type";
import { ShopAddressTypography } from "../ShopAddress/ShopAddress-Styles";

interface Props {
  paginationData: PaginationData | null | undefined;
  pageSelectionChangeHandler: (pageNumber: number) => void | undefined;
}

const Pages = (props: Props) => {

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    props.pageSelectionChangeHandler(value);
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Pagination
            count={props.paginationData?.pageCount}
            onChange={handleChange}
          />{" "}
        </Grid>
        <Grid item>
          <ShopAddressTypography variant="body2">
            {`Showing ${props.paginationData?.starting} - ${props.paginationData?.ending} of ${props.paginationData?.totalResultCount} results`}
          </ShopAddressTypography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Pages;
