import React from "react";
import { ThemeType } from "../../types/type";
import GeneralLogo from "../../assets/images/TGLogo-Black-RGB.svg";
import CahLogo from "../../assets/images/CAH_Logo.svg";
import MSALogo from "../../assets/images/MSA_Logo.svg";
import AmfamLogo from "../../assets/images/AFI_Logo.svg";
import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface Props {
  themeType: ThemeType | undefined;
}

const Logo = (props: Props) => {
  const theme: Theme = useTheme();
  let logo = "";
  let logoWidth = "";
  let logoHeight = "";
  switch (props.themeType) {
    case ThemeType.THE_GENERAL:
      logo = GeneralLogo;
      logoWidth = "226px";
      logoHeight = "75px";
      break;
    case ThemeType.CAH:
      logo = CahLogo;
      logoWidth = "160px";
      logoHeight = "78px";
      break;
    case ThemeType.MSA:
      logo = MSALogo;
      logoWidth = "181px";
      logoHeight = "80px";
      break;
    case ThemeType.AMFAM:
      logo = AmfamLogo;
      logoWidth = "181px";
      logoHeight = "80px";
      break;
    default:
      break;
  }
  return (
    <React.Fragment>
      <div
        style={{
          float: "left",
          minHeight: "80px",
          minWidth: "226px",
        }}
      >
        <a
          href={theme.custom.links.logoLink}
          target="_blank"
          rel="noreferrer"
          data-testid="automation_opco_logo_link"
        >
          <img
            src={logo}
            alt={`${theme.custom.text.opcoName}'s logo`}
            width={logoWidth}
            height={logoHeight}
            data-testid="logoImage"
          ></img>
        </a>
      </div>
    </React.Fragment>
  );
};

export default Logo;
