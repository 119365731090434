import { Theme } from "@mui/material";
import { theGeneralTheme } from "./tgTheme";
import { cahTheme } from "./cahTheme";
import { msaTheme } from "./msaTheme";
import { amfamTheme } from "./afiTheme";

export enum ThemeIdentifiers {
  THE_GENERAL_THEME_ID = "A5FC7DA4D4E34F8C",
  AMFAM_THEME_ID = "C46502ED595BAD41",
  MSA_THEME_ID = "D8958C3071714434",
  CAH_THEME_ID = "B079C1C03BFC7C4B",
}

export default function getThemeById(opcoId: string): Theme {
  let themeName = "";
  switch (opcoId) {
    case ThemeIdentifiers.THE_GENERAL_THEME_ID:
      themeName = "theGeneralTheme";
      break;
    case ThemeIdentifiers.AMFAM_THEME_ID:
      themeName = "amfamTheme";
      break;
    case ThemeIdentifiers.MSA_THEME_ID:
      themeName = "msaTheme";
      break;
    case ThemeIdentifiers.CAH_THEME_ID:
      themeName = "cahTheme";
      break;

    default:
      break;
  }
  return themeMap[themeName];
}

const themeMap: { [key: string]: Theme } = {
  theGeneralTheme,
  cahTheme,
  msaTheme,
  amfamTheme,
};
