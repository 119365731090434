/* istanbul ignore file */
export interface AddressUpdate {
  addressUpdateHandler: (fullAddress: FullAddress) => void;
}

export interface DistanceUpdate {
  distanceUpdateHandler: (distance: string) => void;
}

export interface SearchButtonClick {
  searchButtonClickHandler: () => void;
}

export interface BackNavigationClick {
  backNavigationClickHandler: () => void;
}

export class FullAddress {
  houseNumber: string | null | undefined;
  streetName: string | null | undefined;
  cityName: string | null | undefined;
  stateName: string | null | undefined;
  countryName: string | null | undefined;
  zipCode: string | null | undefined;
  latitude: number | null | undefined;
  longitude: number | null | undefined;
}

export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PaginationData {
  pageCount: number;
  starting: number;
  ending: number;
  totalResultCount: number;
}

export enum ThemeType {
  THE_GENERAL,
  CAH,
  MSA,
  AMFAM,
}

// Module augmentation to support custom object within Theme object

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      colors: {
        primaryColor: string;
        secondaryColor: string;
        headerTopBorderColor: string;
        headerBottomBorderColor: string;
        headerBackgroundColor: string;
        footerBackGroundColor: string;
        listSelectionBackgroundColor: string;
        mainBackgroundColor: string;
        footerTextColor: string;
        footerTopBorderColor: string;
        listItemHoverColor: string;
      };
      links: {
        contactUsLink: string;
        logoLink: string;
        termsAndConditionsLink: string;
        companyInformationLink: string;
        privacyPolicyLink: string;
        legalDisclosuresLink: string;
        dontSellPersonalInfoLink: string;
      };
      text: {
        footerText: string;
        title: string;
        description: string;
        contactNumber: string;
        noShopsFoundMessage: string;
        opcoName: string;
        contactUsText: string;
        companyInformationText: string;
        termsAndConditionsText: string;
        privacyPolicyText: string;
        legalDisclosuresText: string;
        dontSellPersonalInfoText: string;
      };
      styles: {
        headerContactUs: {
          fontFamily: string;
          fontWeight: number;
          fontSize: string;
          color: string;
        };
        headerTitle: {
          fontFamily: string;
          fontWeight: number;
          fontSize: string;
          color: string;
        };
        primaryDescription: {
          fontFamily: string;
          fontWeight: number;
          fontSize: string;
          color: string;
        };
        primaryLabel: {
          fontFamily: string;
          fontWeight: number;
          fontSize: string;
          color: string;
        };
        secondaryDescription: {
          fontFamily: string;
          fontWeight: number;
          fontSize: string;
          color: string;
        };
        themeType?: {
          currentThemeType: ThemeType;
        };
        searchButtonBorderRadius: string;
      };
    };
  }
  interface ThemeOptions {
    custom?: {
      colors?: {
        primaryColor?: string;
        secondaryColor?: string;
        headerTopBorderColor?: string;
        headerBottomBorderColor?: string;
        headerBackgroundColor?: string;
        footerBackGroundColor?: string;
        listSelectionBackgroundColor?: string;
        mainBackgroundColor?: string;
        footerTextColor?: string;
        footerTopBorderColor?: string;
        listItemHoverColor?: string;
      };
      links?: {
        contactUsLink?: string;
        logoLink?: string;
        termsAndConditionsLink?: string;
        companyInformationLink?: string;
        privacyPolicyLink?: string;
        legalDisclosuresLink?: string;
        dontSellPersonalInfoLink?: string;
      };
      text?: {
        footerText?: string;
        title?: string;
        description?: string;
        contactNumber?: string;
        noShopsFoundMessage?: string;
        opcoName?: string;
        contactUsText?: string;
        companyInformationText?: string;
        termsAndConditionsText?: string;
        privacyPolicyText?: string;
        legalDisclosuresText?: string;
        dontSellPersonalInfoText?: string;
      };
      styles?: {
        headerContactUs?: {
          fontFamily?: string;
          fontWeight?: number;
          fontSize?: string;
          color?: string;
          textDecorationColor?: string;
        };
        headerTitle?: {
          fontFamily?: string;
          fontWeight?: number;
          fontSize?: string;
          color?: string;
        };
        primaryDescription?: {
          fontFamily?: string;
          fontWeight?: number;
          fontSize?: string;
          color?: string;
        };
        primaryLabel?: {
          fontFamily?: string;
          fontWeight?: number;
          fontSize?: string;
          color?: string;
        };
        secondaryDescription?: {
          fontFamily?: string;
          fontWeight?: number;
          fontSize?: string;
          color?: string;
        };
        themeType?: {
          currentThemeType?: ThemeType;
        };
        searchButtonBorderRadius?: string;
      };
    };
  }
}
