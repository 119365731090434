import { styled } from "@mui/system";
import { Typography, useTheme } from "@mui/material";

export const ShopAddressTypography = styled(Typography)(() => ({
  color: "#393939",
  fontWeight: 400,
  fontSize: "14px",
  fontFamily: `${useTheme().custom.styles.primaryDescription}`
}));

export const PreferredShopTypography = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: "10px",
    color: `${useTheme().custom.colors.secondaryColor}`,
    fontFamily: `${useTheme().custom.styles.primaryDescription}`,
  }));

export const ShopNameTypography = styled(Typography)({
  color: "#393939",
  fontWeight: 700,
  fontSize: "16px",
});

export const ShopPhoneNumberAndWebSiteTypography = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: "14px",
    textDecoration: "none",
    color: `${useTheme().custom.colors.secondaryColor}`,
    fontFamily: `${useTheme().custom.styles.primaryDescription}`,
  }));