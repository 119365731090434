/* istanbul ignore file */
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header/Header";
import Description from "./components/Description/Description";
import Footer from "./components/Footer/Footer";
import getThemeById from "./themes/themeMapper";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Search from "./components/Search/Search";
import { AppMainBox } from "./App-Styles";

function App() {
  const query = new URLSearchParams(window.location.search);
  const opcoId = query.get("id") ?? "";
  // Retrieve the theme object
  let bodyContent = <PageNotFound />;

  if (opcoId) {
    const activatedTheme = getThemeById(opcoId);
    if (activatedTheme) {
      bodyContent = (
        <ThemeProvider theme={activatedTheme}>
          <Header />
          <AppMainBox>
            <Description />
            <Search opcoId={opcoId} />
          </AppMainBox>
          <Footer />
        </ThemeProvider>
      );
    }
  }

  return <React.Fragment>{bodyContent}</React.Fragment>;
}

export default App;
