/* eslint-disable array-callback-return */
import { FullAddress } from "../../types/type";

enum InfoType {
  HOUSE_NUMBER = "street_number",
  STREET = "route",
  NEIGHBORHOOD = "neighborhood",
  CITY = "locality",
  STATE = "administrative_area_level_1",
  COUNTRY = "country",
  ZIP = "postal_code",
}

export async function getAddressDetailsByPlaceId(placeId: string) {
  try {
    const URI = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    const response = await fetch(URI);
    if (response.status !== 200) {
      throw new Error("'getDetailsByPlaceId' failed");
    }
    const responseData = await response.json();
    const result = responseData.results[0];
    const transformedResponse: FullAddress = {
      houseNumber: mapInformation(result, InfoType.HOUSE_NUMBER),
      streetName: mapInformation(result, InfoType.STREET),
      cityName: mapInformation(result, InfoType.CITY),
      stateName: mapInformation(result, InfoType.STATE),
      countryName: mapInformation(result, InfoType.COUNTRY),
      zipCode: mapInformation(result, InfoType.ZIP),
      latitude: result.geometry.location.lat,
      longitude: result.geometry.location.lng,
    };
    return transformedResponse;
  } catch (error) {
    console.log(error);
    return null;
  }
}

function mapInformation(results: any, mapType: InfoType): string {
  let mappedInfo = "";
  if (results != null && results.address_components != null) {
    const addressComponents = results.address_components;
    addressComponents.find((addressComponent: any) => {
      addressComponent.types.find((type: any) => {
        if (type === mapType) {
          if (type !== InfoType.STATE) {
            mappedInfo = addressComponent.long_name;
          } else {
            mappedInfo = addressComponent.short_name;
          }
        }
      });
    });
  }
  return mappedInfo;
}
