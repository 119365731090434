import { Box, Grid, Typography, Theme } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { LoadingIndicatorMainGrid } from "./LoadingIndicator-Styles";

interface Loader {
  message: string;
}

const LoadingIndicator = (props: Loader) => {
  const theme: Theme = useTheme();
  return (
    <React.Fragment>
      <Box
        title="progressBar"
        sx={{
          width: "100%",
          [theme.breakpoints.down("md")]: {
            pr: "1rem",
            pb: "7rem",
          },
          [theme.breakpoints.up("md")]: {
            pr: "2rem",
          },
        }}
      >
        <LoadingIndicatorMainGrid
          container
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
          sx={{ m: "2rem 2rem 2rem 1rem", pr: "1rem" }}
        >
          <Grid item md={2} sx={{ paddingLeft: "1rem" }}>
            {
              <Box sx={{ position: "relative" }}>
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[
                        theme.palette.mode === "light" ? 200 : 800
                      ],
                  }}
                  size={40}
                  thickness={4}
                  {...props}
                  value={100}
                />
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    color: theme.custom.colors.secondaryColor,
                    animationDuration: "700ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                  size={40}
                  thickness={4}
                  {...props}
                />
              </Box>
            }
          </Grid>
          <Grid item md={10}>
            <Typography
              display="block"
              gutterBottom
              sx={{
                ...theme.custom.styles.primaryDescription,
                paddingTop: "0px",
              }}
            >
              {props.message}
            </Typography>
          </Grid>
        </LoadingIndicatorMainGrid>
      </Box>
    </React.Fragment>
  );
};

export default LoadingIndicator;
