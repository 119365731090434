import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ChevronLeftIcon } from "../svg-Icons/AppIcons";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { BackNavigationClick } from "../../types/type";

const BackNavigation = (props: BackNavigationClick) => {
  const theme: Theme = useTheme();
  return (
    <React.Fragment>
      <Button
        title="View All Results"
        variant="text"
        sx={{
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent",
          },
          textTransform: "none",
        }}
        disableRipple={true}
        onClick={props.backNavigationClickHandler}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <ChevronLeftIcon
            viewBox="0 0 50 50"
            sx={{
              overflow: "visible",
              color: theme.custom.colors.secondaryColor,
              ml: "1rem",
              pt: "10px",
            }}
          />
          <Typography
            tabIndex={0}
            variant="body2"
            sx={{
              ...theme.custom.styles.headerContactUs,
              ml: "0px",
              color: theme.custom.colors.secondaryColor,
            }}
          >
            {"View All Results"}
          </Typography>
        </Stack>
      </Button>
    </React.Fragment>
  );
};

export default BackNavigation;
