import { styled } from "@mui/system";
import { Link, Typography, useTheme } from "@mui/material";

export const HeaderMainDiv = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flexStart",
  height: "60px",
  width: "100%",
}));

export const HeaderBorderDiv = styled("div")(() => ({
  width: "100%",
  borderTop: `2px solid ${useTheme().custom.colors.headerTopBorderColor}`,
}));

export const HeaderLink = styled(Link)(() => ({
  ...useTheme().custom.styles.headerContactUs,
  textDecoration: "none",
}));

export const SecondaryHeaderTypography = styled(Typography)(() => ({
  ...useTheme().custom.styles.headerTitle,
  [useTheme().breakpoints.down("md")]: {
    fontSize: "24px",
    padding: "1rem",
  },
}));
